<template>
  <div class="menu-page">
    <x-table
      :no-data-text="CA('menuManage_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      rowKey="id"
      :config="config"
      @add="add"
    >
    </x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="900"
      @on-visible-change="modalShow"
      class="menu-modal"
      :transfer="false"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <Row>
          <Col span="11">
            <FormItem label="菜单名称" prop="name">
              <Input v-model="form.name"></Input>
            </FormItem>
          </Col>
          <Col offset="1" span="11">
            <FormItem label="菜单层级" prop="levels">
              <Select v-model="form.levels">
                <Option :value="1">目录</Option>
                <Option :value="2">菜单</Option>
                <Option :value="3">按钮</Option>
              </Select>
            </FormItem>
          </Col>
          <!-- <Col span="11" offset="1">
            <FormItem label="排序" prop="num">
              <Input v-model="form.num"></Input>
            </FormItem>
          </Col> -->
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="路由地址" prop="url">
              <Input v-model="form.url"></Input>
            </FormItem>
          </Col>
          <Col offset="1" span="11">
            <FormItem label="文件地址" prop="tips">
              <Input v-model="form.tips"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="菜单图标" prop="icon">
              <upload-image
                :max="1"
                v-model="form.icon"
                :clearFlag="!modal.show"
              ></upload-image>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="是否显示" prop="isopen">
              <Select v-model="form.isopen">
                <Option :value="1">显示</Option>
                <Option :value="0">隐藏</Option>
              </Select>
            </FormItem>
          </Col>
        </Row>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      config: {
        filter: {
          add: {
            addBtnName: "新增菜单",
            ca: "menuManage_add",
          },
          searchBtnHide: true,
        },
      },
      table: {
        columns: [
          {
            title: "菜单名称",
            minWidth: 100,
            tree: true,
            key: "name",
          },
          {
            title: "菜单类型",
            minWidth: 100,
            render: (h, { row }) => {
              return (
                <span>
                  {row.levels === 1
                    ? "目录"
                    : row.levels === 2
                    ? "菜单"
                    : "按钮"}
                </span>
              );
            },
          },
          {
            title: "菜单图标",
            minWidth: 100,
            render: (h, { row }) => {
              if (!row.icon) return;
              return (
                <img
                  src={this.getImgUrl(row.servicePath, row.icon)}
                  style="width:35px;height: 35px"
                />
              );
            },
          },
          {
            title: "路由地址",
            minWidth: 100,
            key: "url",
          },
          {
            title: "文件地址",
            minWidth: 120,
            key: "tips",
          },
          {
            title: "操作",
            width: 300,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("menuManage_add") && row.levels != 3 && (
                    <a style="margin-right: 10px" onClick={() => this.add(row)}>
                      新增下级菜单
                    </a>
                  )}
                  {this.CA("menuManage_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("menuManage_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a style="margin-right: 10px">删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },

      form: {
        name: "",
        pcode: "0",
        icon: "",
        // num: "",
        url: "",
        levels: "1",
        tips: "",
        menutype: "wfpt_app",
        isopen: 1,
      },

      rules: {
        name: [{ required: true, message: "请填写菜单名称" }],
        // num: [{ required: true, message: "请输入排序数字" }],
        levels: [{ required: true, message: "请选择菜单层级" }],
        isopen: [{ required: true, message: "请选择菜单是否显示" }],
        // tips: [{ required: true, message: "请填写文件地址" }],
      },
      iconLibraryShow: false,
      menuList: [],

      menuId: "",
    };
  },
  methods: {
    add(row) {
      if (row) {
        this.menuId = row.id;
      }
      this.form.pcode = (row && row.code) || "0";
      this.modal = {
        show: true,
        title: row ? `新增【${row.name}】的下级菜单` : "新增菜单",
        submitLoading: false,
      };
    },
    getList() {
      if (!this.CA("menuManage_check")) return;
      this.$post(this.$api.MENU.LIST, {
        menutype: "wfpt_app",
      }).then((res) => {
        let str = JSON.stringify(res).replace(/childList/g, "children");
        let n = JSON.parse(str);
        if (this.menuId) {
          this.expandData(n);
        }
        this.table.data = n;
      });
    },
    expandData(n) {
      let result = false;
      n.forEach((item) => {
        if (item.id == this.menuId) {
          item._showChildren = true;
          result = true;
          return;
        }
        if (item.children && item.children.length > 0) {
          let subResult = this.expandData(item.children);
          if (subResult) {
            item._showChildren = true;
            result = subResult;
          }
        }
      });
      return result;
    },
    edit(row) {
      this.menuId = row.id;
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.id = row.id;
      this.form.icon = row.servicePath + row.icon;
      this.modal = {
        show: true,
        title: "编辑菜单",
        submitLoading: false,
      };
    },
    del(id) {
      this.$post(this.$api.MENU.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },
    modalShow(visible) {
      if (visible) return;
      this.form = {
        // num: "",
        name: "",
        pcode: "0",
        icon: "",
        url: "",
        levels: "",
        tips: "",
        menutype: "wfpt_app",
        isopen: 1,
      };
      this.iconLibraryShow = false;
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        params.icon = "image" + this.form.icon.split("image")[1];
        this.modal.submitLoading = true;
        this.$post(params.id ? this.$api.MENU.EDIT : this.$api.MENU.ADD, params)
          .then(() => {
            this.modal.show = false;
            this.getList();
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
.menu-page {
  width: 100%;
  height: 100%;
}
</style>